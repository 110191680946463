export const breadcrumbList = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '提成设置',
    path: ''
  },
  {
    name: '方案列表',
    path: ''
  }
]


export const addbreadcrumbList = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '业绩规则',
    path: '/main/score/scoreRuleList'
  },
  {
    name: '新增业绩规则',
    path: ''
  }
]


